import React, { useState } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Breadcrumb, Modal } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import logo from "../../images/logo.svg";
import HTMLReactParser from "html-react-parser";
import {Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import review from '../../images/review-mob.svg';
import reviewTab from '../../images/review-tab.svg';
import ContactCard from "../ContactCard/ContactCard";
import TeamCard from "../TeamCard/TeamCard";
import BrandLogo from "../../images/logo.svg";
import OfficeBookAppointment from "./OfficeBookAppointment";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import "./OfficeDetail.scss";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon } from "react-share";
import { GetURL, getVideoId } from "../utils";
import ContactFormPage from "../forms/contact";
import $ from "jquery";
import { isMobile } from "react-device-detect";
import '../NewsEvents/NewsEvents.scss'
import NewsCard from '../NewsCard/NewsCard';
import GoogleMap from "./GoogleMap";
import Slider from "react-slick";
import { graphql, useStaticQuery } from 'gatsby';

import {PageLinks} from '../../components/common/constant';
import PlayVideo from '../PlayVideo/PlayVideo';

// Header component

const Marker = () => <img src={logo}/>;

const OfficeDetail = (props) => {
    const [isPlay, setPlay] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState("");
    const [show, setShow] = useState(false);

    var { pathname } = useLocation()
    var ofcUrl = process.env.GATSBY_SITE_URL + pathname

    let processedImages = JSON.stringify({});
    if (props?.data?.imagetransforms?.Tile_Image_Transforms) {
        processedImages = props?.data?.imagetransforms?.Tile_Image_Transforms;
    }

    const shareurl = typeof window !== "undefined" ? window.location.href : "";

    const [shareIcons, setShareicons] = React.useState(false);

    const openShareicons = () => {
        setShareicons(true)
        if (shareIcons === true) {
            setShareicons(false)
        }
    }
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                newsAndVideos (sort: "Sort") {
                    _id
                    Title
                    Url
                    Category {
                        Title
                    }
                    Tile_Image {
                        alternativeText
                        url
                    }
                    Video_Url
                    Sub_Category
                    imagetransforms
                }
            }
        }
    `)
    const trackerShare = event => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: "Share - social icons",
            formType: event + " - " + shareurl,
            formId: "Share",
            formName: "Share",
            formLabel: "Share",
        })
    }
    var settings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            }
        ]
    };
    let schemaAddress;
    let schemaLocality;
    let schemaPostalCode;
    let keywords;
    let areaserved;
    if(props.data.Url === "head-office"){
        schemaAddress = "4th floor, 247 - 249, London"
        schemaLocality = "Cromwell road, Kensington"
        schemaPostalCode = "SW5 9GA"
        keywords = "Kensington estate agents, estate agents in Kensington"
        areaserved = "Kensington, Chelsea, Earl's Court, and surrounding areas like W8, SW5, SW7, SW10, and SW3"
    }
    if(props.data.Url === "kensington"){
        schemaAddress = "8 Hogarth place"
        schemaLocality = "Kensington, London"
        schemaPostalCode = "SW5 0QT"
        keywords = "letting agents in Kensington, Kensington letting agents"
        areaserved = "Kensington, Chelsea, Earl's Court, and the surrounding areas, including W8, SW5, SW7, SW10, and SW3"
    }
    var Lets_Together_Content = props?.globalContent?.Lets_Work_Together

    return (
        <React.Fragment>
            <Helmet>
                <script type="application/ld+json">{`{
                    "@context": "https://schema.org",
                    "@type": "RealEstateAgent",
                    "name": "${props?.data?.Meta_Title}",
                    "@id": "${ofcUrl}",
                    "url": "${ofcUrl}",
                    "telephone": "${props?.data?.Phone}",
                    "description": "${props?.data?.Meta_Description}",
                    "logo": "${BrandLogo}"
                    "keywords": "${keywords}",
                    "areaServed": "${areaserved}",
                    "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "${schemaAddress}",
                      "addressLocality": "${schemaLocality}",
                      "postalCode": "${schemaPostalCode}",
                      "addressCountry": "United Kingdom"
                    },
                    "geo": {
                      "@type": "GeoCoordinates",
                      "latitude": ${props?.data?.Latitude},
                      "longitude":${props?.data?.Longitude}
                    },
                    "openingHoursSpecification": [{
                      "@type": "OpeningHoursSpecification",
                      "dayOfWeek": [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday"
                      ],
                      "opens": "09:00",
                      "closes": "18:00"
                    }],
                    "sameAs": [
                      "https://www.facebook.com/tlcREALestateagents",
                      "https://twitter.com/tlcestateagents",
                      "https://instagram.com/tlcestateagents",
                     "https://www.youtube.com/channel/UC7wmgcJX1xgWru_EB--b0UQ",
                      "https://www.linkedin.com/company/tylden-lofts-and-calvert"
                      ]                   
                }`}
                </script>    
            </Helmet>
            <section className="office-detail">
                <Container>
                    <Row>
                        <Col md={12} className="bio-wrapper">
                            <div className="bio-img">
                                <ImageTransform
                                    imagesources={props?.data?.Tile_Image?.url}
                                    renderer="pic-src" imagename="offices.Tile_Image.tile"
                                    attr={{ alt: props?.data?.Tile_Image?.alternativeText ? props?.data?.Tile_Image?.alternativeText : props?.data?.Title, className: 'm-0' }}
                                    imagetransformresult={processedImages}
                                    id={props?.data?._id}
                                />
                                {(props?.data?.Video_Url && !isPlay) && <i className="video-icon cursor-pointer" onClick={() => setPlay(true) }></i>}
                                {(props?.data?.Video_Url && isPlay) && <div className="fullscreen-video">
                                    {isPlay && 
                                        <React.Fragment>
                                            <div className="d-block">
                                                <PlayVideo
                                                    isOpen={isPlay}
                                                    videoId={props?.data?.Video_Url}
                                                    stopPlay={setPlay}
                                                    width={'100%'} height={'100%'}
                                                    fullscreen={true}
                                                />
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>}
                            </div>
                            <div className="bio-details-wrapper">
                                <div className="bio-details">
                                    <h1>
                                        {props?.data?.Title}
                                    </h1>
                                    <span className="sm-info">{props?.data?.Address}</span>
                                    {/* <span className="sm-info text-underline ps-3 cursor-pointer" onClick={() => {
                                        $('html, body').animate({
                                            scrollTop: $("#office-map").offset().top-130
                                        }, 0)
                                    }}><u>View Map</u></span> */}
                                    <div className="contact-info">
                                        <a href="javascript:;" className="btn d-none d-md-block" onClick={() => setShow(true)}>
                                            get in touch
                                        </a>
                                        <div className="info">
                                            <a href="javascript:;" onClick={() => setShow(true)} className=" mail phone d-none d-md-flex cursor-pointer"> 
                                           
                                                <i className="icon-phone-grey"></i>
                                                {props?.data?.Phone}
                                            </a>
                                            <a href="javascript:;" onClick={() => setShow(true)} className=" mail phone d-none d-md-flex cursor-pointer"> 
                                                <i className="icon-mail"></i>
                                                <span>{props?.data?.Email}</span>
                                            </a>
                                            <a href="javascript:;" className="mail cursor-pointer position-relative share-container">
                                                <i className="icon-share" onClick={openShareicons}></i>
                                                <span onClick={openShareicons}>Share</span>
                                                <div className="position-absolute">
                                                    {shareIcons && (
                                                        <div onClick={openShareicons} className="d-flex flex-row property-share-social-icons2 mobile-details-socila-share ps-3">
                                                            <FacebookShareButton
                                                                onClick={() =>
                                                                    trackerShare("FacebookShareButton")
                                                                }
                                                                url={shareurl}
                                                                className="my-share-button facebook-share mx-1"
                                                            >
                                                                <FacebookIcon
                                                                    size={32}
                                                                    round={false}
                                                                    borderRadius={`10`}
                                                                />
                                                            </FacebookShareButton>
                                                            <TwitterShareButton
                                                                onClick={() =>
                                                                    trackerShare("TwitterShareButton")
                                                                }
                                                                url={shareurl}
                                                                className="my-share-button twitter-share mx-1"
                                                            >
                                                                <TwitterIcon
                                                                    size={32}
                                                                    round={false}
                                                                    borderRadius={`10`}
                                                                />
                                                            </TwitterShareButton>
                                                            <LinkedinShareButton
                                                                onClick={() =>
                                                                    trackerShare("LinkedinShareButton")
                                                                }
                                                                url={shareurl}
                                                                className="my-share-button linkedin-share mx-1"
                                                            >
                                                                <LinkedinIcon
                                                                    size={32}
                                                                    round={false}
                                                                    borderRadius={`10`}
                                                                />
                                                            </LinkedinShareButton>
                                                            <WhatsappShareButton
                                                                onClick={() =>
                                                                    trackerShare("WhatsappShareButton")
                                                                }
                                                                url={shareurl}
                                                                className="my-share-button whatsapp-share mx-1"
                                                            >
                                                                <WhatsappIcon
                                                                    size={32}
                                                                    round={false}
                                                                    borderRadius={`10`}
                                                                />
                                                            </WhatsappShareButton>    
                                                        </div>
                                                    )}
                                                </div>
                                            </a>
                                            <a href="https://maps.app.goo.gl/Z64fdCayEW3UD4i37" className=" mail phone d-none d-md-flex cursor-pointer"> 
                                                <i className="icon-location"></i>
                                                Location
                                            </a>
                                        </div>

                                        {/* <div className="contact-us">
                                            <span className="talk-us">
                                                Talk to me in:

                                            </span>
                                            <div className="country">
                                                <img src={france} alt="france" />
                                            </div>

                                            <div className="country">
                                                <img src={china} alt="china" />
                                            </div>
                                        </div> */}
                                    </div>

                                </div>
                            </div>
                        </Col>
                        <Col md={12} lg={6} className="top-content ">
                            {props?.data?.Opening_Hours && <div className="content-wrapper opening-hours"><hr className="hr"/>
                                {HTMLReactParser(props?.data?.Opening_Hours)}
                            </div>}<hr className="hr"/>
                            <div className="review-wrap">
                                            <tr >
                                                <td>
                                                    <picture>
                                                        <source media='(min-width: 768px)' srcSet={reviewTab}/>
                                                        <img src={review} alt="review" />
                                                    </picture>
                                                </td>
                                                <td>
                                                    <div className="text-wrap td office-review">Rated <strong>4.9 out of 5</strong> from our valued customers.</div>
                                                </td>
                                            </tr>
                                        </div>
                                        <br></br>
                                        <hr className="hr"/>
                        </Col>
                        <div className="content-wrapper">
                                <Row >
                                    <Col className="lg-8">
                                        <div>
                                            <h2 className="content-heading">{props?.data?.Content_Heading}</h2>
                                            {props?.data?.Content && HTMLReactParser(props?.data?.Content)}
                                        </div>
                                    </Col>
                                    <Col className="lg-4">
                                      <GoogleMap mapLink={props?.data?.Map_Link}/>
                                    </Col>
                                </Row>
                            </div>
                            
                            {props?.data?.Select_Team_Member &&
                            <div>
                              <Row>
                                <Col>
                                    <ContactCard
                                    blockImg={props?.data?.Select_Team_Member?.Tile_Image?.url}
                                    blockTitle={props?.data?.Select_Team_Member?.Name}
                                    blockContent={`We are the Kensington and Chelsea garden square specialists. Interested in selling or letting, get in touch with our team.`}
                                    blockBtn="Speak to our team"
                                    blockLink={props?.data?.Contact_Link ? GetURL(props?.data?.Contact_Link?._id) : `/about/meet-the-team/${props?.data?.Select_Team_Member?.Url}`}
                                    blockVideo={props?.data?.Select_Team_Member?.Video_Url} 
                                />
                                </Col>
                                <Col>
                                   <OfficeBookAppointment Lets_Together_Content={Lets_Together_Content}/>
                                </Col>
                             </Row>
                            
                            </div>}
                        
                    </Row>
                    <hr/>
                    <div className="team-section ">
                        <Row>
                            <Col xs="12" className="d-flex flex-row justify-content-between team-list-sugg">
                                <p>
                                    Our {props?.data?.Title} Team
                                </p>
                                <Link to={`/${PageLinks.news_videos}/`}>View more</Link>
                            </Col>
                            <Container>
                                <div className='event-slider'>
                                    {/* <span className='sub-title'>{props?.data?.Title}</span> */}
                                    <Slider className="slick-arrow" {...settings}>
                                         {props?.teams?.map(item => (
                                            <NewsCard 
                                                key={item._id} 
                                                id={item._id} 
                                                // index={index}
                                                imagename={`news-and-videos.Tile_Image.list_hm`}
                                                selectedVideo={selectedVideo}
                                                setSelectedVideo={(val) => setSelectedVideo(val)}
                                                newsCard={{
                                                    newsTitle: item.Title,
                                                    newsTag: item.Sub_Category,
                                                    newsImg: item.Tile_Image.url,
                                                    newsImgAlt: item.Tile_Image.alternativeText,
                                                    newsImgTransforms: item.imagetransforms,
                                                    newsUrl: item.Url+"/",
                                                    newsVideo: true,
                                                    videoID: item.Video_Url,
                                                    id: item._id
                                                }} 
                                                video={item.Video_Url}
                                                path={`/${PageLinks.team_url}/`}
                                            />
                                        ))}
                                    </Slider>
                                </div>
                            </Container>
                            {props?.teams?.map(item => (
                                <Col xs="12" md="6" lg="4">
                                    {/* <NewsCard 
                                        key={item._id} 
                                        id={item._id} 
                                        // index={index}
                                        imagename={`news-and-videos.Tile_Image.list_hm`}
                                        selectedVideo={selectedVideo}
                                        setSelectedVideo={(val) => setSelectedVideo(val)}
                                        newsCard={{
                                            newsTitle: item.Title,
                                            newsTag: item.Sub_Category,
                                            newsImg: item.Tile_Image.url,
                                            newsImgAlt: item.Tile_Image.alternativeText,
                                            newsImgTransforms: item.imagetransforms,
                                            newsUrl: item.Url+"/",
                                            newsVideo: true,
                                            videoID: item.Video_Url,
                                            id: item._id
                                        }} 
                                        video={item.Video_Url}
                                        path={props.path}
                                    /> */}
                                    {/* <TeamCard 
                                        key={item._id} 
                                        id={item._id} 
                                        selectedVideo={selectedVideo}
                                        setSelectedVideo={(val) => setSelectedVideo(val)}
                                        teamCard={{
                                            memberPic: item.Tile_Image?.url,
                                            memberName: item.Name,
                                            memberNumber: item.Mobile,
                                            memberEmail: item.Email,
                                            memberLink: item.Url+"/",
                                            memberBio: 'Bio',
                                            blockVideo: item.Video_Url
                                        }}
                                        video={item.Video_Url}
                                        path={"/about/meet-the-team/"}
                                    /> */}
                                </Col>
                            )
                            )}
                        </Row>
                    </div>
                </Container>
            </section>
            {/* <div className="map-module w-100" id="office-map">
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: process.env.GATSBY_GOOGLE_MAP_KEY
                    }}
                    defaultCenter={{
                        lat: 51.489592,
                        lng: -0.191138
                    }}
                    defaultZoom={17}
                >
                    <Marker
                        lat={props?.data?.Latitude}
                        lng={props?.data?.Longitude}
                    />
                </GoogleMapReact>
            </div> */}
            <Modal className="form-popup" onHide={() => setShow(false)} show={show} contentClassName="p-0" size="sm">

                <Modal.Body className="p-5">
                    <div className="card-title h5 text-center mb-3">Get in Touch - {props?.data?.Title}</div>
                    <button onClick={() => setShow(false)} type="button" className="close-modal">
                        <i className="icon-close-modal"></i>
                    </button> 
                    <div className="form-block career-form">
                        <ContactFormPage email={props?.data?.Email} formname={`Get in Touch - ${props?.data?.Title}`}/>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment >
    )
}
export default OfficeDetail;
